import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`It's not often that you can ride from the end of the glaciers down to the
river at the bottom of the valley. In Flims Laax you can.`}</p>
    <br />
    <p>{`After a chairlift and a gondola we pedal another 400vm in thin air, almost
to the end of Vorab Glacier. Long ago we left the tree line behind us and
now rocks dominate. We can see that the glacier has done it's work up here
and look over smooth carved rock slabs.`}</p>
    <br />
    <p>{`Over these rocks the descent begins and our eyes look for the line between
the way marks. It feels like we are in an ocean of rocks. The waves froze
to the rocks we are riding on. A constant up and down on solid underground.`}</p>
    <br />
    <p>{`Slowly it becomes greener and stains of gras and dirt starts to spread.
Now the trail is clearly defined and fast over the wide open alpine meadows,
with still many rocks inbetween. Lots of corners and compressions make our
legs burn and it feels like a big pumptrack.`}</p>
    <TrailguidePlugin content="lat=46.8495&lng=9.2305" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open these trails directly in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=13,46.8495,9.2305"
        }}>{`web-app`}</a></p>
    </p>
    <p>{`From the Plaun plain we enter the forest and enjoy the dirt and many roots.
The Green Valley trail connects us to the famous Runca Trail with its many
berms tables and wooden elements. Fast and flowy it winds through the woods
and brings us finally back down to Flims after 1500vm of biking on a big
variety of trails.`}</p>
    <br />
    <p>{`Make sure to check out also the many other possibilities and the idyllic
trails along the Rheinschlucht. Tip: after long day on the mountain bike,
the Cauma or Crestasee offers great cool down and a beautiful swim.`}</p>
    <br />
    <p>{`If you think of visiting Flims Laax, the three days of the Trail Trohpy
Enduro race in August could be a great option. At the moment you can win
two tickets for the race `}<a parentName="p" {...{
        "href": "/news/winticketsflimslaax18/"
      }}>{`here on Trailguide.`}</a></p>
    <br />
    <br />
    <h4>{`Vorab Trail`}</h4>
    <p>{`Start on 2600m.a.s.l, you are riding on rocks, smoothly carved by glaciers.
See the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3368"
      }}>{`here`}</a></p>
    <Image src="destinations/flimslaaxvorab.jpg" mdxType="Image" />
    <br />
    <h4>{`Höenweg Nagens`}</h4>
    <p>{`Further down, the nature becomes greener, but the trails are still technical.
See the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3371"
      }}>{`here`}</a></p>
    <Image src="destinations/flimslaaxnagens.jpg" mdxType="Image" />
    <br />
    <h4>{`Green Valley`}</h4>
    <p>{`Great trails with lots of roots in the woods.
See the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3369"
      }}>{`here`}</a></p>
    <Image src="destinations/flimslaaxgreenvalley.jpg" mdxType="Image" />
    <br />
    <h4>{`Runca Trail`}</h4>
    <p>{`Winds through the green. The famous Runca Trail.
See the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3366"
      }}>{`here`}</a></p>
    <Image src="destinations/flimslaaxruncatrail.jpg" mdxType="Image" />
    <br />
    <h4>{`Runca Trail`}</h4>
    <p>{`Fast and with lots of berms, tables and wooden elements.
See the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3366"
      }}>{`here`}</a></p>
    <Image src="destinations/flimslaaxruncatrail2.jpg" mdxType="Image" />
    <br />
    <h4>{`Never End`}</h4>
    <p>{`Also a lot of flow you will find in the berms and jumps of the Never End trail.
See the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3368"
      }}>{`here`}</a></p>
    <Image src="destinations/flimslaaxneverend.jpg" mdxType="Image" />
    <br />
    <h4>{`Never End`}</h4>
    <p>{`With almost 8km the trail really feels like it would never end. But who wants that?
See the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3368"
      }}>{`here`}</a></p>
    <Image src="destinations/flimslaaxneverend2.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      